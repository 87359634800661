@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

.transform-left {
    transform: translateX(-50px);
}

.external-menu-overlay.menu-open {
    visibility: visible;
    opacity: 1;
}

.external-menu.menu-open {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
}

.external-menu {
    background-color: #353535;
    width: 302px;
    font-family: 'Roboto', sans-serif;
    transform: translateX(305px);
}

.external-menu li a {
    font-size: 14px;
    color: #a0a4ae;
    -webkit-text-size-adjust: 100%;
    letter-spacing: 1px;
    -webkit-font-smoothing: antialiased;
}

.external-menu li a:hover {
    color: #FFFFFF;
}

.external-menu-close {
    fill: #a0a4ae;
}

.external-menu-close svg {
    width: 15px;
    height: 15px;
}

.external-menu-close:hover {
    fill: #FFFFFF;
}

.external-menu .list-inner {
    height: 0;
    font-family: 'Roboto Slab', serif;
    background: rgba(255,255,255,.025);
}

.external-menu .list-inner.list--open {
    height: auto;
}

.external-menu .list-inner a {
    font-size: 13px;
}

.external-border {
    border-color: rgba(255,255,255,.03);
}

.list-more span {
    background-color: #a0a4ae;
}

.list-more span:first-of-type {
    width: 11px;
    height: 1px;
}

.list-more.list--open span:last-of-type {
    display: none;
}

.list-more span:last-of-type {
    position: relative;
    height: 10px;
    width: 1px;
    top: -5px;
    right: -5px;
}

.my-radiobox {
    display: grid;
    place-items: center;
    width: 24px !important;
    height: 24px !important;
    padding: 0;
    color: #ffffff;
    position: relative;
    cursor: pointer;
    appearance: none;
}

.my-radiobox:before {
    content: "";
    width: 100%;
    height: 100%;
    line-height: 1;
    color: inherit;
    border-width: 1px;
    border-style: solid;
    border-color: #000000;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
}

.my-radiobox:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
}

.my-radiobox:checked:before {
    border-width: 1px;
}
.my-radiobox:checked:after {
     width: calc(100% - 8px);
     height: calc(100% - 8px);
     background: #F58220;
}

.babylonjs {
    background-color: #959595;
}
.babylon-4 {
    width: 100%;
}
.loaded .babylon-4 {
    width: 80%;
}
.babylon-2 {
    width: 60%;
}
.ld-aside-hidden {
    opacity: 0;
    visibility: hidden;
}
.ld-aside, .ld-summary, .ld-info {
    min-width: 244px;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}
.loaded .ld-aside,.loaded .ld-summary,.loaded .ld-info {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}
.ld-aside--open, .ld-summary--open, .ld-info--open {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}
.ld-summary, .ld-info {
    display: none;
}
.ld-summary--open, .ld-info--open {
    display: block;
    opacity: 1;
    visibility: visible;
}
.ld-configure {
    transform: translateX(-100%);
}
.loaded .ld-configure {
    transform: translateX(-70%);
}
.loaded .ld-configure--active, .ld-configure--active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}
.ld-configure--active img {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.loaded .ld-aside {
    width: 20%;
}

.loaded .ld-aside--open {
    width: 40%;
}

.loaded .ld-side-info-open {
    width: 70%;
}

.loaded .ld-aside:not(.ld-aside--open) .task-name {
    display: none;
}

.loaded .ld-aside:not(.ld-aside--open) .task-image {
    margin-right: 0;
    width: 100%;
}

.loaded .ld-aside:not(.ld-aside--open) .checkmark {
    right: -11px;
    border: 0;
}

.loaded .ld-aside:not(.ld-aside--open) .task-component {
    padding: 0;
}

.variation-no_support_stand_geometry,
.variation-a_frame_no_support_stand_geometry,
.task-component.task-c-winch_hidden {
    display: none;
}

@media (max-width: 768px) {
    .loaded .ld-aside, .loaded .ld-summary, .loaded .ld-info {
        transform: translateX(100%);
    }
    .ld-aside--open,
    .ld-summary--open,
    .ld-info--open,
    .loaded .ld-aside--open,
    .loaded .ld-summary--open,
    .loaded .ld-info--open
    {
        transform: translateX(0);
    }
    .loaded .ld-configure {
        transform: translateX(-100%);
    }
    .loaded .ld-configure--active, .ld-configure--active {
        transform: translateX(35px);
    }
    .babylon-2, .loaded .babylon-4 {
        width: 100%;
    }
    .ld-aside, .loaded .ld-aside {
        width: 100%;
        padding-left: 40px;
    }
    .ld-configure--active {
        transform: translateX(30px);
    }

}